
.midContent {text-align: center;}

.midContent h1 {color: #1f295c;font-size: 42px;margin-bottom: 20px;}

.blueBtn {display: inline-block;padding: 15px 40px;background: #1f295c;border-radius: 50px;color: #fff;font-size: 16px;}

.blueBtn:hover {background: #50250f;color: #fff;}
.midSection {min-height: calc(100vh - 99px);display: flex;align-items: center; padding: 0 30px; justify-content: center;}
.roatateImg{
    height: 400px;
    overflow: hidden;
    padding-top: 50px;
    margin-bottom: 20px;
}

.listInline {margin: 0;padding: 0;list-style-type: none;display: flex;justify-content: center;align-items: center;gap: 40px;}

.midContent p {font-size: 16px;color: #1f1f1f;max-width: 1300px;}

.roatateImg img {
  animation: rotate 120s infinite linear;
  z-index: -1;
  max-width: 100%;
  max-height: 200%;
}

@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@media only screen and (max-width: 767px){
    .roatateImg {
        padding-top: 20px;
        max-width: 300px;
        margin: 0 auto 20px;
        height: 150px;
    }
}

  